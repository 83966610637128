body { overflow-x: hidden; }
.bannerRow {
    margin-top: 50px;
    margin-bottom: 50px;
    float: left;
    display: block;
    width: 100%;
}

.bannerText h1 {
    font-size: 50px;
}

.bannerText {
    margin-top: 0;
    padding: 0px 250px;
}
.bannerText p {
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
}
.about {
    background: #1d1d1b;
    float: left;
    width: 110%;
    transform: rotate(-6deg);
    padding: 100px 0 100px;
    margin-left: -5%;
}
.aboutBox {
    transform: rotate(6deg);
}
.vision {
    background: #f4f3f4;
    float: left;
    width: 110%;
    transform: rotate(-6deg);
    padding: 100px 0 100px;
    margin-left: -5%;
}
.visionBox {
    transform: rotate(6deg);
}
.satisfaction {
    background: #1d1d1b;
    float: left;
    width: 110%;
    transform: rotate(-6deg);
    padding: 100px 0 100px;
    margin-left: -5%;
}
.satisfactionBox {
    transform: rotate(6deg);
}
.clientsLogo {
background: #f4f3f4;
    float: left;
    width: 110%;
    transform: rotate(-6deg);
    padding: 150px 0 100px;
    margin-left: -5%;
}
.clientBox {
    transform: rotate(6deg);
}
.no-of-client {
    margin-bottom: 70px;
}
.no-of-client h5 {
    text-align: center;
    font-size: 40px;
    color: #555;
    margin-bottom: 0px;
}
.no-of-client span {
    text-align: center;
    width: 100%;
    display: block;
    color: #777;
    font-size: 14px;
}
.logo3, .logo4 {
    margin-bottom: 50px;
}

.logo3 img, .logo4 img { height:35px; }

.logo3.text-left { text-align: left; }
.logo3.text-center { text-align: center; }
.logo4.text-left { text-align: left; }
.logo4.text-center { text-align: center; }

.meet-our-team {
    width: 100%;
    display: block;
    float: left;
    margin-top: 135px;
    margin-bottom: 30px;
}
.meet-our-team h2 {
    text-align: center;
    font-size: 50px;
        margin-bottom: 50px;
}
.teams img {
    width: 100%;
}
.teams a img {
    width: 20px;
    float: right;
    margin-top: 22px;
}
.teams h4 {
    font-size: 22px;
    font-weight: 400;
    margin-top: 20px;
        margin-bottom: 0px;
        color: #333;
}
.teams span {
    font-weight: 300;
    font-size: 16px;
    color: #555;
    display: block;
        margin-bottom: 10px;
}
.teams b {
    font-weight: 300;
    color: #888;
    font-size: 16px;
}
.teams p {
    font-size: 17px;
    font-weight: 300;
    color: #555;
}

.teams {
    width: 100%;
    display: block;
    margin-bottom: 50px;
}
.getStart {
    border: 2px solid #333;
    height: auto;
    padding: 30px 70px 15px;
    margin-bottom: 90px;
    float: left;
}
.getStart p {
    font-size: 35px;
    font-weight: 400;
}
.getStart a {
    float: right;
    background: #000;
    color: #fff;
    padding: 14px 48px;
    font-size: 22px;
    font-weight: 300;
    margin-top: 20px;
}
.about-banner img {
    width: 100%;
}
.about-banner {
    float: left;
}






@media (min-width: 320px) and (max-width:767px) {
.logo3 img, .logo4 img { height:22px; }
.bannerText {
    margin-top: 0;
    padding: 0px 25px;
}
.bannerText p {
    font-size: 16px;
    line-height: 32px;
}
.bannerRow {
    margin-top: 120px;
    margin-bottom: 70px;
    float: left;
    display: block;
    width: 100%;
}
.bannerText h1 {
    font-size: 35px;
}
.clientsLogo {
    width: 125%;
    transform: rotate(-6deg);
    padding: 80px 0 30px;
    margin-left: -14%;
}
.clientBox {
    transform: rotate(6deg);
    width: 80%;
    margin: 0px auto;
}
.no-of-client h5 {
    font-size: 22px;
}
.no-of-client span {
    font-size: 12px;
}
.meet-our-team h2 {
    font-size: 35px;
    margin-bottom: 40px;
}
.meet-our-team {
    margin-top: 85px;
}
.getStart {
    border: 2px solid #333;
    height: auto;
    padding: 20px 20px 35px;
    margin-bottom: 88px;
    text-align: center;
}
.getStart p {
    font-size: 20px;
    font-weight: 400;
}
.getStart a {
    float: none;
    padding: 11px 35px;
    font-size: 17px;
    margin-top: 0;
}

}

@media (min-width:768px) and (max-width:992px) {
.bannerText {
    margin-top: 0;
    padding: 0px 100px;
}
.bannerRow {
    margin-top: 150px;
    margin-bottom: 120px;
}
.getStart {
    border: 2px solid #333;
    height: auto;
    padding: 20px 20px 10px;
    margin-bottom: 90px;
}
.getStart p {
    font-size: 24px;
    font-weight: 400;
}
.getStart a {
    float: right;
    background: #000;
    color: #fff;
    padding: 12px 25px;
    font-size: 22px;
    font-weight: 300;
    margin-top: 10px;
}

}

@media (min-width: 992px) { }

@media (min-width: 1200px) { }

.projectlist-item-container {
    width: 230px;
    height: 200px;
    position: relative;
    display: inline-flex;
    margin-right: 30px;
    margin-bottom: 100px;
    cursor: pointer;
}

.project-name {
    /*font-size: 24px;*/
    height: 150px;
    width: 200px;
    /*color: #44566C;*/
    /*font-weight: bold;*/
    flex-grow: 1;
    /*align-items: center;*/
    display: flex;
    padding: 12px;
    transition: background, color 0.3s ease-in-out;
}

.projectlist-item-container:hover .project-name {
    background: #012d80;
    color: white;
}

.project-action-button {
    width: 84px;
    padding: 4px;
    font-size: 12px;
}

.project-draft {
    padding: 12px;
    height: 34px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.projectlist-item-container:hover .project-draft {
    background: #012d80;
}

.project-action {
    opacity: 0;
    display: flex;
    /*justify-content: space-between;*/
    background: white;
    padding: 12px;
    text-align: center;
    justify-self: center;
    /*transition: opacity 0.3s ease-in-out;*/
    /*border-bottom-left-radius: 12px;*/
    /*border-bottom-right-radius: 12px;*/
}

.projectlist-item-container:hover .project-action {
    opacity: 1;
}

.projectlist-item-card {
    border: none;
    border-radius: 12px;
    width: 200px;
    height: 250px;
    box-shadow: 0px 8px 16px #012d80;
}

.projectlist-item-shadow {
    content: "";
    position: absolute;
    left: 40px;
    right: 40px;
    top: 80%;
    bottom: 0%;

    background: #012d80;
    box-shadow: 0px 8px 16px #a56d00, 0px 32px 32px #a56d00;

}

.label {
    font-size: 22px;
    font-weight: 500;
}


