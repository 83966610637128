.pagee {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    border-radius: 10px;
}
.main-signin-wrapperr {
    flex: 1;
    display: flex;
    justify-content: center;
}
.signpages .details {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
	background-color: #f6ece4;
}
.header-brand-img {
    line-height: 2.5rem;
    vertical-align: middle;
    width: auto;
}
.pos-absolute {
    position: absolute;
}
.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.ht-100 {
    height: 300px;
}
.font-20 {
	font-size: 20px; 
}
.login_heading {
	font-size: 16px;
    font-weight: 600;
	margin-top: 25px;
}

@media (min-width: 1300px) {
.signpages {
    width: 60%;
    border-radius: 6px;
    margin: 5em auto;
    display: flex;
}
}
@media (max-width: 990px) {
.signpages {
    width: 60%;
    border-radius: 6px;
    margin: 5em auto;
    display: flex;
}
}
@media screen and (max-width: 640px) {
.signpages {
    width: 90%;
}
}